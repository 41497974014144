<template>
    <div class="alert-list__associate" v-for="associate in alert.associates" :key="associate.alertId">
        <div class="alert-list__border-circle alert-list--border-circle-first">
            <div class="alert-list__border-circle">
                <img :src="filesPath + associate.associateImage" width="150" height="150" class="alert-list__image"
                    v-if="associate.associateImage">

                <img src="@/assets/images/user-profile.svg" width="150" height="150" class="alert-list__image" v-else>
            </div>
        </div>

        <div class="alert-list__associate-info">
            <div class="alert-list__associate-name">{{ associate.associateName }}</div>
            <div class="alert-list__associate-location" v-if="associate.lastLocation">
                {{ associate.lastLocation }} - {{ associate.lastLocationFloor }}
            </div>
            <div class="alert-list__associate-location" v-else>
                QR Room/Area Unavailable, Click Live Tracking To View GPS Location.
            </div>
        </div>

        <router-link :to="`alert_timeline/${associate.alertId}`" class="alert-list__button">
            Live Tracking
            <img class="alert-list__button-icon" src="@/assets/icons/green-point-status.svg" />
        </router-link>

        <span class="alert-list__location-updated" :id="`notification-${associate.alertId}`">
            <img src="@/assets/icons/vuesax/linear/info-circle-red.svg" alt="">
            Location Updated
        </span>

        <!-- *** Disable in MVP 1 *** -->
        <!-- <div class="alert-list__associate-options">
            <a href="#" placeholder="asdasd">
                <img src="@/assets/icons/solar_stream-broken.svg" />
            </a>
            <a href="#">
                <img src="@/assets/icons/ph_file-video-light.svg" />
            </a>
            <a href="#">
                <img src="@/assets/icons/solar_chat-dots-broken-white.svg" />
            </a>
        </div> -->
    </div>
</template>

<script setup>
import { alertAssociatesTrackingEvent } from '@/sockets-events/AlertAssociatesTrackingEvent.js'
import { usePanicAlertStore } from '../../../stores/PanicAlertStore.js'
import { onMounted, onBeforeUnmount } from 'vue'

const { joinAssociatesTrackingEvent, leaveAssociatesTrackingEvent } = alertAssociatesTrackingEvent()
const { alert, getPanicAssociatesList } = usePanicAlertStore()
const filesPath = process.env.VUE_APP_FILES_PATH

onMounted(() => {
    // * Get list of associates in alert 
    getPanicAssociatesList()

    // * Join in alert associates tracking event
    joinAssociatesTracker()
})

onBeforeUnmount(() => {
    alert.associates.forEach(associate => {
        leaveAssociatesTrackingEvent(associate.alertId)
    })
})

function joinAssociatesTracker() {
    alert.associates.forEach(associate => {
        joinAssociatesTrackingEvent(associate.alertId)
            .listen(".location", (location) => {
                const locationUpdateNotification = document.querySelector(`#notification-${associate.alertId}`)
                const selectedAssociate = alert.associates.find(a => a.associateId === associate.associateId)

                // * Show location updated notification
                if (location.locationName !== selectedAssociate.lastLocation) {
                    locationUpdateNotification.classList.remove('alert-list--hide-location-updated')
                    locationUpdateNotification.classList.add('alert-list--show-location-updated')

                    setTimeout(() => {
                        locationUpdateNotification.classList.remove('alert-list--show-location-updated')
                        locationUpdateNotification.classList.add('alert-list--hide-location-updated')
                    }, 2000);
                }

                // * Update location name
                if (location.locationName) {
                    selectedAssociate.lastLocation = location.locationName
                    selectedAssociate.lastLocationFloor = location.locationFloor
                }
            })
    })
}
</script>