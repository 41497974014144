<template>
    <div class="alert-list__calls-logs" v-if="associatesList.length">
        <h2>911 Calls Logs</h2>

        <div class="alert-list__call-logs-content">
            <div class="alert-list__call-log" v-for="(associate, index) in associatesList" :key="index">
                    <img :src="`${filesPath}${associate.associatePhoto}`" class="alert-list__call-associate-image" width="50" height="50" alt="" v-if="associate.associatePhoto">
                    <img src="@/assets/images/user-profile.svg" class="alert-list__call-associate-image" width="50" height="50" v-else>
                <div class="alert-list__call-associate-info">
                    <span>{{ associate.associateName }}</span>
                    <p>{{ associate.rapidMessage }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { rapidSosEvent } from '../../../sockets-events/RapidSosEvent'
import { onMounted, ref } from 'vue'
import { panicAlertAssociatesServices } from '../Services/PanicAlertAssociatesServices'

const { joinRapidSosEvent } = rapidSosEvent()
const { getCallLogsService } = panicAlertAssociatesServices()
const associatesList = ref([])
const filesPath = process.env.VUE_APP_FILES_PATH

onMounted(() => {
    setCallLogs()
    rapidSosEvents()
})

function rapidSosEvents() {
    joinRapidSosEvent()
        .listen(".rapid-events", (event) => {
            if (event.eventType.eventId == 2) setCallLogs()
        })
}

async function setCallLogs() {
    const response = await getCallLogsService()

    if (response.hasErrors) return console.log(`Error getting call logs records: ${response.message}`)

    associatesList.value = response.data
}
</script>