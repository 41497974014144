import { httpRequests } from "../../../helpers/HttpRequests"

export function panicAlertAssociatesServices() {
    const { getRequest } = httpRequests()

    function getCallLogsService() {
        return getRequest('/web/rapid_sos/get_call_logs')
    }

    return { getCallLogsService }
}